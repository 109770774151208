import { ADMIN_USER_TYPE } from "../../services/UserService";
import LoadingPage from "../Common/LoadingPage"
import VideoViewer from "../Files/Video/VideoViewer";
import Spinner from '../Common/Tailwind/Spinner';
import { ArrowPathIcon } from '@heroicons/react/20/solid'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon, MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const SlotRecordedLectureVideos = ({ lectureData, user = ADMIN_USER_TYPE }) => {


    if (lectureData === null) {
        return <Spinner />;
    }

    return (
        <>
            <div className="w-full max-w-7xl px-2 py-6 sm:py-8 lg:px-4 lg:py-10">
                <div className="w-full divide-y divide-gray-900/10">
                    <h2 className="text-base md:text-lg lg:text-xl font-semibold leading-10 tracking-tight text-[var(--SecondaryColor)]">JEETA found {lectureData.recorded_lecture_videos.length} related video{lectureData.recorded_lecture_videos.length > 1 ? "s" : ""}.</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {lectureData.recorded_lecture_videos.map((recorded_lecture_video, index) => (
                            <Disclosure key={recorded_lecture_video.question} as="div" className="pt-6">
                                <dt>
                                    <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                        <span className="text-base font-semibold leading-7">{recorded_lecture_video.recorded_lecture_video.title}</span>
                                        {/* <span className="text-base font-semibold leading-7">{lectureData.teacher_id}</span> */}
                                        <span className="ml-6 flex h-7 items-center">
                                            <ChevronDownIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                                            <ChevronUpIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                                        </span>
                                    </DisclosureButton>
                                </dt>
                                <DisclosurePanel as="dd" className="mt-2 px-5">
                                    <VideoViewer videoId={recorded_lecture_video.recorded_lecture_video.id}
                                        videoType='recorded_lecture'
                                        defaultApproverId={lectureData.teacher_id} videoTitle={recorded_lecture_video.recorded_lecture_video.title} />

                                </DisclosurePanel>
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </>)
}

export default SlotRecordedLectureVideos;