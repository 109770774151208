import React from 'react'


import { useParams } from 'react-router-dom';
import ZoomView from '../../Components/Zoom/ZoomView';
import { TEACHER_USER_TYPE } from '../../services/UserService';

const TeacherZoom = () => {
    const { slotId } = useParams();
    return (
        <>
            {/* <Hero
                cName="hero"
                img={image}
                title="Live Class"
                description="Engage and Motivate Yourself with JEETA!"
            /> */}
            <ZoomView slotId={parseInt(slotId)} user={TEACHER_USER_TYPE} />
        </>
    )
};

export default TeacherZoom;