import React from 'react'

import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';

import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';

import { useParams } from 'react-router-dom';
import ZoomView from '../../Components/Zoom/ZoomView';

const StudentZoom = () => {
    const { slotId } = useParams();
    return (
        <>
            {/* <Hero
                cName="hero"
                img={image}
                title="Live Class"
                description="Engage and Motivate Yourself with JEETA!"
            /> */}
            <ZoomView slotId={parseInt(slotId)} user={STUDENT_USER_TYPE} />
        </>
    )
};

export default StudentZoom;