import React, { useRef, useState } from 'react';
import Modal from 'react-modal';

import './NTA.css'

import Latex from 'react-latex';
import NTAMarkingScheme from './NTAMarkingScheme';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { useNTATest } from './NTATestContext';
import { AiFillCloseCircle } from 'react-icons/ai';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const NTATestPaper = ({ isOpen, onRequestClose, parentId = null, user = ADMIN_USER_TYPE }) => {

    const options = ['A', 'B', 'C', 'D'];
    const contentRef = useRef(null);


    const {
        testData,
        activeSection,
        testStatus,
        setTestStatus,
        testSections,
        testQuestions,
        testParagraphs,
        questionStyleById,
        setQuestionStyleById,
        hasAgreedInstructions
    } = useNTATest();

    const generatePDF = async () => {
        const content = contentRef.current;
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        // Save current scroll position
        const scrollTop = content.scrollTop;

        // Reset scroll to top
        content.scrollTop = 0;

        const canvas = await html2canvas(content, {
            height: content.scrollHeight,
            windowHeight: content.scrollHeight,
            scale: 2,
        });

        const imgData = canvas.toDataURL('image/png');
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = pdfWidth / imgWidth;
        const totalPages = Math.ceil(imgHeight * ratio / pdfHeight);

        for (let page = 0; page < totalPages; page++) {
            if (page > 0) pdf.addPage();

            const position = page * pdfHeight / ratio;
            pdf.addImage(
                imgData,
                'PNG',
                0,
                -(position),
                pdfWidth,
                imgHeight * ratio
            );
        }

        pdf.save('test_paper.pdf');

        // Restore original scroll position
        content.scrollTop = scrollTop;
    };

    const renderSection = (sectionId, sectionIndex) => {
        return (
            <div className="NTATestPaperSection flex">

                {/* <h3 className="sectionTitle">Section {sectionIndex + 1}</h3> */}

                {/* <NTAMarkingScheme sectionId={sectionId} user={user} /> */}
                {testQuestions.filter((testQuestion) => testQuestion.test_section_id === sectionId && testQuestion.paragraph_order == null).map((testQuestion, index) => renderQuestion(testQuestion.question, index))}
                {testParagraphs.filter((testParagraph) => testParagraph.test_section_id === sectionId).map((testParagraph, index) => renderParagraph(testParagraph.paragraph, index))}
            </div>
        )
    }

    const renderAnswer = (currentQuestionData, answer, index) => {
        if (['single choice', 'multi choice'].includes(currentQuestionData.question_subtype)) {
            return (
                <div className="option flex" >

                    <label className="answer-label">({options[index]}) </label>

                    <Latex>{answer.answer.answer_text}</Latex>

                </div >
            );
        }
    }

    const renderQuestion = (questionData, questionIndex) => {
        return (
            <>
                <h3 className="NTAQuestionNumber">Question {questionIndex + 1}</h3>

                <div className="NTATestPaperQuestion">
                    {questionData.question_text && (
                        <div className="question-text">
                            <Latex>{questionData.question_text}</Latex>
                        </div >
                    )}

                    {/* Displaying the Question Images */}
                    {(questionData.images.length > 0) && (
                        <div className={questionData.question_text.length === 0 ? 'question-image-only' : 'question-image'}>
                            {questionData.images.map((img, index) => (
                                <img key={index} src={img.image.url} alt="Question related" />
                            ))}
                        </div>
                    )}

                    {/* <div className="NTAanswers flex">
                        {questionData.answers.map((answer, index) => (
                            <span key={answer.answer.id} className="answer-container">
                                {renderAnswer(questionData, answer, index)}
                            </span>
                        ))}
                    </div> */}

                </div>
            </>
        );
    }


    const renderParagraph = (paragraphData, index) => {
        return (
            <>
                <h3 className="NTAQuestionNumber">Paragraph {index + 1}</h3>

                <div className="NTATestPaperQuestion">

                    {paragraphData.paragraph_text && (
                        <div className="question-text flex">
                            <Latex>
                                {paragraphData.paragraph_text}
                            </Latex>
                        </div>
                    )}

                    {/* Render paragraph images */}
                    {(paragraphData.images.length > 0) && (
                        <div className="question-image flex">
                            {paragraphData.images.map(img => (
                                <img key={img.image.id} src={img.image.url} alt="Paragraph related" draggable={false} />
                            ))}
                        </div>
                    )}

                    {
                        paragraphData.questions.map((question, index) => renderQuestion(question.question, index))
                    }
                </div>
            </>
        );
    }

    return (
        <Modal overlayClassName="customModal" className="NTAModal flex" isOpen={isOpen} onRequestClose={onRequestClose} parentSelector={() => parentId ? document.getElementById(parentId) : document.body}>
            <button onClick={generatePDF}>Download PDF</button>
            <div ref={contentRef} className="NTATestPaper roboto-medium flex">
                {/* Test Name  */}
                <h3 className="test-name">{testData.test_name}</h3>

                {testSections.map((testSection, sectionIndex) => renderSection(testSection.id, sectionIndex))}
            </div>

            <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
        </Modal>
    );
}


export default NTATestPaper;
