import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import './app.css';
import PrivateRoute from './Components/Authentication/PrivateRoute';
import AdminZoom from './routes/Admin/AdminZoom';
import StudentZoom from './routes/Student/StudentZoom';
import TeacherZoom from './routes/Teacher/TeacherZoom';

const ZoomApp = () => {
    return (
        <div className="zoom-app">
            <Routes>
                <Route path="/zoom/admin/liveclass/slots/:slotId/meeting" element={<PrivateRoute allowedRoles={['admin']}><AdminZoom /></PrivateRoute>} />
                <Route path="/zoom/student/liveclass/slots/:slotId/meeting" element={<PrivateRoute allowedRoles={['student']}><StudentZoom /></PrivateRoute>} />
                <Route path="/zoom/teacher/liveclass/slots/:slotId/meeting" element={<PrivateRoute allowedRoles={['teacher']}><TeacherZoom /></PrivateRoute>} />

                {/* <Route path="/zoom/admin/liveclass/slots/:slotId/meeting/:zoomMeetingId" element={<PrivateRoute allowedRoles={['admin']}><AdminZoomOnly /></PrivateRoute>} />
                <Route path="/zoom/student/liveclass/slots/:slotId/meeting/:zoomMeetingId" element={<PrivateRoute allowedRoles={['student']}><StudentZoomOnly /></PrivateRoute>} /> */}
            </Routes>
        </div>
    )
}

export default ZoomApp;